import request from "@/services/request"
import baseUrl from "@/services/baseUrl"

// let path =  `${baseUrl.main}/${baseUrl.envName != 'development'? "cs/": ''}cs/v1/`;
let path = `${baseUrl.main}/v1/locations/bo/`;

// this is for globally used APIs only
export default {
  getRegions: (params = {}) => request.post(path + "regions/list", params),
  addRegion: (params = {}) => request.post(path + "regions/new", params),
  editRegion: (params = {}) => request.put(path + "regions/" + params.regionId, params),
  toggleVisibility: (params = '') => request.post(path + "regions/toggle/" + params),

  getCities: (params = {}) => request.post(path + "cities/list", params),
  addCity: (params = {}) => request.post(path + "cities/new", params),
  editCity: (params = {}) => request.put(path + "cities/" + params.cityId, params),
  toggleCityVisibility: (params = '') => request.post(path + "cities/toggle/" + params),

  getNeighborhoods: (params = {}) => request.post(path + "neighborhoods/list", params),
  addNeighborhood: (params = {}) => request.post(path + "neighborhoods/new", params),
  editNeighborhood: (params = {}) => request.put(path + "neighborhoods/" + params.neighborhoodId, params),
  toggleNeighborhoodVisibility: (params = '') => request.post(path + "neighborhoods/toggle/" + params)
}